import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Image, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наши услуги | Академия кикбоксинга Health Freaks
			</title>
			<meta name={"description"} content={"В Академия кикбоксинга Health Freaks, Мы предлагаем широкий спектр услуг, предназначенных для удовлетворения потребностей каждого энтузиаста кикбоксинга."} />
			<meta property={"og:title"} content={"Наши услуги | Академия кикбоксинга Health Freaks"} />
			<meta property={"og:description"} content={"В Академия кикбоксинга Health Freaks, Мы предлагаем широкий спектр услуг, предназначенных для удовлетворения потребностей каждого энтузиаста кикбоксинга."} />
			<meta property={"og:image"} content={"https://solivareber.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://solivareber.com/img/boxing.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://solivareber.com/img/boxing.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://solivareber.com/img/boxing.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://solivareber.com/img/boxing.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://solivareber.com/img/boxing.png"} />
			<meta name={"msapplication-TileImage"} content={"https://solivareber.com/img/boxing.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" quarkly-title="Product-5">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal 500 56px/1.2 --fontFamily-sans"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				color="--darkL1"
			>
				Наши услуги
			</Text>
			<Text
				margin="0px 0px 20px 0px"
				text-align="center"
				font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				color="--darkL1"
				padding="0px 20px 0px 20px"
				lg-padding="0px 0 0px 0"
				lg-margin="0px 0px 50px 0px"
			>
				В Академия кикбоксинга Health Freaks, Мы предлагаем широкий спектр услуг, предназначенных для удовлетворения потребностей каждого энтузиаста кикбоксинга.
			</Text>
			<Hr
				min-height="10px"
				margin="0px 0px 50px 0px"
				border-color="--color-darkL1"
				width="40px"
				border-width="2px 0 0 0"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 70px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Image
						src="https://solivareber.com/img/3.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
					/>
					<Hr
						min-height="10px"
						margin="0px 0px 10px 0px"
						border-color="--color-darkL1"
						width="40px"
						border-width="2px 0 0 0"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
						Комплексные программы кикбоксинга
						<br />
						<br />
						Классы начинающих: идеально подходит для тех, кто новичок в кикбоксингах, сосредоточенность на фундаментальных методах, базовой работе ног и основополагающей фитнесе.
						<br />
						Усовершенствованное обучение: для опытных практиков эти сессии включают в себя продвинутые комбинации, стратегии спарринга и высокоинтенсивную кондиционирование.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 30px 0px">
						<Image
							src="https://solivareber.com/img/4.jpg"
							display="block"
							max-width="100%"
							margin="0px 0px 25px 0px"
							height="300px"
							width="100%"
							object-fit="cover"
							sm-height="220px"
							lg-height="200px"
							md-height="350px"
						/>
						<Hr
							min-height="10px"
							margin="0px 0px 10px 0px"
							border-color="--color-darkL1"
							width="40px"
							border-width="2px 0 0 0"
						/>
						<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
							Специализированные услуги
							<br />
							<br />
							Персональное обучение: один на один с занятиями с опытными тренерами, обеспечивая персонализированное внимание для техники уточнения и ускорения прогресса.
							<br />
							Сила и кондиционирование: сфокусированные тренировки, предназначенные для повышения физической силы, выносливости и общей фитнеса, дополняя навыки кикбоксинга.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Image
						src="https://solivareber.com/img/5.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
					/>
					<Hr
						min-height="10px"
						margin="0px 0px 10px 0px"
						border-color="--color-darkL1"
						width="40px"
						border-width="2px 0 0 0"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
						Дополнительные предложения
						<br />
						<br />
						Руководство по питанию: консультации, доступные для того, чтобы помочь участникам оптимизировать свою диету для пиковой производительности и выздоровления.
						<br />
						События сообщества: регулярно организовывали мероприятия и соревнования, способствуя духу товарищества и соревновательного духа в академии.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Связаться с уродством здоровья
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Для получения дополнительной информации о наших занятиях, графиках обучения или для забронирования сеанса:
					<br />
					Телефон: +7 800 080 1111
					<br />
					Email: info@solivareber.com
					<br />
					<br />
					Академия кикбоксинга Health Freaks – Где каждая сессия формирует чемпионы
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://solivareber.com/img/6.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});